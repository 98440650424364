<template>
	<div id="dialog">
		<h3>Add New Artist</h3>

		<!-- Duplicate: we already have an artist with this name -->
		<template v-if="duplicateName">
			<p class="error-msg">
				We already have {{ categoryArticle }} named
				<a target="_blank" :href="`/art/${duplicateNamePath}`">{{ duplicateName }}</a
				>.
			</p>
			<p>
				If you wish to add a different {{ categoryPersonified }} with the same name, please confirm
				and resubmit.
			</p>
			<FormToggle v-model="artist.forceDuplicate" :label="`Different ${categoryPersonified}`" />
			<div class="buttons-wrap">
				<FormButton value="Go Back" :level="1" :wait="waitingCancel" @click="resetDuplicateName" />
				<FormButton
					value="Resubmit"
					:level="2"
					:wait="waitingSubmit"
					@click="confirm"
					:disabled="!artist.forceDuplicate"
				/>
			</div>
		</template>

		<!-- Regular add artist form -->
		<div class="structured-form" v-else>
			<FormRadios
				v-model="firstLast"
				:radioOptions="radioOptions"
				label="Name Format"
				name="name-type"
				class="radios"
			/>
			<template v-if="firstLast">
				<FormText
					v-model="artist.firstName"
					:error="errors.firstName"
					label="First + Middle Name"
					placeholder="First name"
					preventKeys="[<>]"
					:autoFocus="true"
					@update="errors.firstName = null"
				/>
				<FormText
					v-model="artist.lastName"
					:error="errors.lastName"
					label="Last Name"
					placeholder="Last name"
					preventKeys="[<>]"
					@update="errors.lastName = null"
				/>
			</template>
			<FormText
				v-else
				v-model="artist.name"
				:error="errors.name"
				label="Name / Pseudonym"
				placeholder="Eg. Banksy"
				preventKeys="[<>]"
				@update="errors.name = null"
			/>
			<template v-if="showLatinNameField">
				<p id="latin-name-notice">
					Please provide alternative spelling in Latin/Roman alphabet.
				</p>
				<br />
				<template v-if="firstLast">
					<FormText
						label="English First Name"
						v-model="artist.latinFirstName"
						:error="errors.latinFirstName"
						placeholder="English first name"
						preventKeys="[<>]"
						@update="errors.latinFirstName = null"
					/>
					<FormText
						label="English Last Name"
						v-model="artist.latinLastName"
						:error="errors.latinLastName"
						placeholder="English last name"
						preventKeys="[<>]"
						@update="errors.latinLastName = null"
					/>
				</template>
				<FormText
					v-else
					label="English Name / Pseudonyn"
					v-model="artist.latinName"
					:error="errors.latinName"
					placeholder="English name or pseudonym"
					preventKeys="[<>]"
					@update="errors.latinName = null"
				/>
			</template>
			<FormDropdown
				v-model="artist.category"
				:error="errors.category"
				:options="ddCategory"
				label="Main Category"
				@update="errors.category = null"
			/>
			<div class="buttons-wrap">
				<FormButton :level="1" :wait="waitingSubmit" value="Submit" @click="doConfirm" />
				<FormButton :level="2" value="Cancel" :wait="waitingCancel" @click="doCancel" />
			</div>
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormRadios from '@/components/FormRadios'
import FormText from '@/components/FormText'
import FormDropdown from '@/components/FormDropdown'
import FormToggle from '@/components/FormToggle'
import FormButton from '@/components/FormButton'

// Internal
import isNonLatin from '@/use/NonLatin'
import { article } from '@/use/StringMagic'
import artCategories from '@/use/ArtCategories'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'DialogArtistCreate',
	components: { FormRadios, FormText, FormDropdown, FormToggle, FormButton },
	setup() {
		const dialogStore = useDialogStore()
		const apiStore = useApiStore()
		const artistApi = apiStore.loadApi('artist')
		return { dialogStore, artistApi }
	},
	data() {
		return {
			// v-model values
			firstLast: true,

			// Error states
			errors: {
				firstName: null,
				lastName: null,
				name: null,
				latinFirstName: null,
				latinLastName: null,
				latinName: null,
				category: null,
			},

			// When you submit an already existin artist,
			// this is set to the name, triggering warning UI.
			duplicateName: null,
			duplicateNamePath: null,

			// When user inputs non-latin name we'll display an extra
			// fields for a latin character alternative spelling.
			showLatinNameField: false,

			// Radio buttons
			radioOptions: [
				{
					text: 'First & last name',
					value: true,
				},
				{
					text: 'Single name',
					value: false,
				},
			],

			// Category dropdown
			ddCategory: artCategories.dropdown(),
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, [
			'params',
			'waitingSubmit',
			'doConfirm',
			'waitingCancel',
			'doCancel',
			'critical',
		]),
		artist() {
			return {
				firstName: this.firstLast && this.submittedName ? this.submittedName.firstName : '',
				lastName: this.firstLast && this.submittedName ? this.submittedName.lastName : '',
				name: !this.firstLast && this.submittedName ? this.submittedName.name : '',
				latinFirstName: '',
				latinLastName: '',
				latinName: '',
				category: null,
				forceDuplicate: false,
			}
		},

		// Split name submitted in artists input into first, last and name
		submittedName() {
			const nameSplit = this.splitName(this.params.submittedName)
			return {
				firstName: nameSplit[0],
				lastName: nameSplit[1],
				name: this.params.submittedName,
			}
		},
		// Combined string with firstName + lastName + name
		// This is used to detect any changes and run detectNonLatin()
		nameValue() {
			return this.artist.firstName + this.artist.lastName + this.artist.name
		},
		// Display friendly category
		category() {
			if (!this.artist.category) return ''
			return this.artist.category == 'other' ? 'creator' : this.artist.category.replace(/-/gi, ' ')
		},
		// Photography --> photographer
		categoryPersonified() {
			if (!this.artist.category) return ''
			return artCategories.personify(this.artist.category)
		},
		// "a" photographer or "an" artist
		categoryArticle() {
			return article(this.categoryPersonified, true)
		},
	},
	watch: {
		nameValue() {
			this.detectNonLatin()
		},
		// Smartly transfer values between first/last and pseudonym
		// in case the user already filled in some stuff before switching
		firstLast(newValue) {
			if (newValue) {
				if (!this.artist.name) return
				const nameSplit = this.splitName(this.artist.name)
				this.artist.firstName = nameSplit[0]
				this.artist.lastName = nameSplit[1]
				this.artist.name = null
			} else {
				if (!this.artist.firstName && !this.artist.lastName) return
				// prettier-ignore
				this.artist.name = [this.artist.firstName, this.artist.lastName].join(' ')
				this.artist.firstName = null
				this.artist.lastName = null
			}
		},
	},
	mounted() {
		// Preload name from whatever was filled in into the input
		this.artist.firstName = this.submittedName.firstName
		this.artist.lastName = this.submittedName.lastName

		// Set validation stage
		this.dialogStore.setValidation(this.processForm)
		// this.dialogStore.disableEnterKey()
	},
	methods: {
		// Split name into first + last
		// First word becomes first name, rest becomes last name
		splitName(name) {
			const nameSplit = name.split(' ')
			const firstName = nameSplit.shift()
			const lastName = nameSplit.join(' ')
			return [firstName, lastName]
		},

		// Detect if artists name is in a non-Latin script language
		detectNonLatin() {
			const nameValue = this.firstLast ? this.artist.firstName + this.artist.lastName : this.artist.name
			if (isNonLatin(nameValue)) {
				this.showLatinNameField = true
			} else {
				this.showLatinNameField = false
			}
		},

		// This is used as boolean validation stage in dialogStore.
		// If anything goes wrong, the onConfirm will not be executed.
		async processForm() {
			if (!this._validate()) return false
			const apiResonseOk = await this._sendToApi()
			if (!apiResonseOk) return false
			return true
		},

		// Validate
		_validate() {
			let error = false
			if (this.firstLast) {
				// First name
				if (!this.artist.firstName) {
					this.errors.firstName = 'Required'
					error = true
				}
				// Last name
				if (!this.artist.lastName) {
					this.errors.lastName = 'Required'
					error = true
				}
				// Latin first & last name
				if (this.showLatinNameField) {
					// First
					if (!this.artist.latinFirstName) {
						this.errors.latinFirstName = 'Required'
						error = true
					} else if (isNonLatin(this.artist.latinFirstName, { strict: true })) {
						this.errors.latinFirstName = 'Invalid'
						error = true
					}
					// Last
					if (!this.artist.latinLastName) {
						this.errors.latinLastName = 'Required'
						error = true
					} else if (isNonLatin(this.artist.latinLastName, { strict: true })) {
						this.errors.latinLastName = 'Invalid'
						error = true
					}
				}
			} else {
				// Name
				if (!this.artist.name) {
					this.errors.name = 'Required'
					error = true
				}
				// Latin name
				if (this.showLatinNameField) {
					if (!this.artist.latinName) {
						this.errors.latinName = 'Required'
						error = true
					} else if (isNonLatin(this.artist.latinName, { strict: true })) {
						this.errors.latinName = 'Invalid'
						error = true
					}
				}
			}
			if (!this.artist.category) {
				this.errors.category = 'Required'
				error = true
			}
			return !error
		},

		// API call
		async _sendToApi() {
			// Post to API
			const { status, data: artist, statusText } = await this.artistApi.create(this.artist)

			// Duplicate name --> Block and ask the user if they want
			// to create another artist with the same name.
			if (status == 409) {
				this.duplicateNamePath = artist.namePath
				this.duplicateName = artist.name
				return false
			} else if (status != 200) {
				handleApiError({ status, data: artist, statusText, action: 'creating this artist' })
				return false
			}

			// Success
			this.dialogStore.setCustomParams({
				artistName: artist.name,
				artistId: artist.id,
			})
			return true
		},

		// When user decides not to submit duplicate.
		resetDuplicateName() {
			this.duplicateName = null
			this.duplicateNamePath = null
		},
	},
}
</script>

<style scoped lang="scss">
#dialog h3 {
	margin-bottom: 0.3rem;
}
#dialog .radios {
	flex: 2 0;
}
#latin-name-notice {
	font-size: $small;
}
// Error msg above submit
.error-msg.small {
	margin-top: -0.1rem;
	margin-bottom: 0.2rem;
}
</style>
